import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SuiBox from "_softUI/components/SuiBox";
import {  NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import TopnavElement from "./TopNavElement";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { userActions } from '_actions';
import { history } from '_helpers';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomerService from '_components/CustomerService';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';


export const TopNavigation = ({ brand, routes }, props) => {

  const dispatch = useDispatch();
  function handleLogOut() {
    dispatch(userActions.logout())
  }


  const settings = [
    {
      'Name': 'User profile',
      'Action': () =>  history.push('/UserProfile')
    },
    {
      'Name': 'Logout',
      'Action': handleLogOut
    },
  ];

  var authState = useSelector(state => state.authentication);

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, routeKey, route, href, requiredRoles, action }) => {
    let returnValue;


    if (requiredRoles && requiredRoles.length > 0) {

      let print = false;
      for (let i = 0; i < requiredRoles.length && print === false; i++) {
        console.log(authState.user.roles.find(x => x === requiredRoles[i]))
        print = authState.user.roles && authState.user.roles.find(x => x === requiredRoles[i])

      }

      if (print) {

        returnValue = <TopnavElement type={type} name={name} icon={icon} title={title} noCollapse={noCollapse} routeKey={routeKey} route={route} href={href} action={action}/>


      }
    }
    else {
      returnValue = <TopnavElement type={type} name={name} icon={icon} title={title} noCollapse={noCollapse} routeKey={routeKey} route={route} href={href} action={action} />
    }





    return returnValue;
  });

  function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorHelp, setAnchorHelp] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenHelpMenu = (event) => {
    setAnchorHelp(event.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setAnchorHelp(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  console.log(renderRoutes)
  return (



    <AppBar position="sticky" sx={{ borderBottom: '1px solid rgb(233, 233, 233)', backgroundColor: '#fff' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <SuiBox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && <SuiBox component="img" src={brand} alt="FreightOpt" width="150px" />}

          </SuiBox>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            {renderRoutes}
          </Box>
          <Box sx={{ flexGrow: 0,  }}>
            <Tooltip title="Help">
              <IconButton onClick={handleOpenHelpMenu} sx={{ p: 0,marginRight:2}}>
              <Avatar   sx={{backgroundColor:'rgb(211 215 208)'}}> <QuestionMarkIcon sx={{width:'1.2em',height:'1.2em'}}/></Avatar>
              </IconButton>
            </Tooltip>  
            <Menu
              sx={{ mt: '65px' }}
              id="menu-appbar"
              anchorEl={anchorHelp}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorHelp)}
              onClose={handleCloseHelpMenu}
            >
              <CustomerService/>
            </Menu>
        
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{backgroundColor:'rgb(211 215 208)'}} ><SettingsIcon/></Avatar>
              </IconButton>
            </Tooltip>  
            <Menu
              sx={{ mt: '65px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.Name} onClick={setting.Action}>
                  <Typography textAlign="center">{setting.Name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>

  );
};
