import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

import { Progress, Success } from '../Shared'
import { Typography } from '@mui/material';

import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import { AdrRow } from './adrRow'

import SuiBadge from "_softUI/components/SuiBadge";

import SuiButton from '_softUI/components/SuiButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { instantPricingActions, ShipmentInformationFormActions } from '../../_actions';

import { stackableOptions, adrOptions, adrTypes, fullTrailerTypes, containerTypes, typeOptionsAir, typeOptionsSea, typeOptionsInstant, typeOptionsRoad } from '_helpers'






function getTypeOptionsList(type) {

  switch (type) {
    case 1:
      return typeOptionsRoad
    case 2:
      return typeOptionsAir
    case 3:
      return typeOptionsSea

    default:
      return typeOptionsInstant
  }

}



export function GoodsRow({ goods, index, handleRowChange, removeGoodsLine, showDescription, size, calculating, handleRowChangeWithMaxLenght, state, adrSelection, handleAdrClassChange, shipmentFormType }) {

  const dispatch = useDispatch();

  const row = goods[index]
  console.log('adroptiot', row)
  const instant = state.shipmentType === 2
  const last = (index + 1) === goods.length

  const typeOptions = getTypeOptionsList(state.transportationType)
  const showFullTruck = row.typeCode === 5
  const showContainer = row.typeCode === 6
  const specialType = showContainer || showFullTruck
  const showLabel = (showDescription && goods[index - 1]?.adr) || index === 0



  const quantityCounter = state.goods.reduce((accumulator, goodsRow) => accumulator + parseInt(goodsRow.quantity), 0);

  return (
    <>
      <Grid container xs={12} spacing={2}
        sx={{
          backgroundColor: !showDescription && '#fff',
          borderRadius: '0px',
          // mb: !last ? '0.1rem' : last && row.adr ? '2rem' : '',
          mb: '0.5rem',
          width: '100%',
          marginLeft: 'auto',
          paddingLeft: '0px !important',
        }} >

        <Grid container item xs={12} alignItems="flex-start" spacing={2} >

          {/* Secion 1: Kollityyppi (tai truck/container) - Määrä */}
          <Grid item xs={12} justifyContent="center" alignItems="flex-start" md={specialType ? 3 : !showDescription ? 3 : 2} spacing={0.99} sx={{ paddingLeft: '0px !important' }} >
            <Grid container item xs={12} spacing={0.99}>
              <Grid container item xs={12} spacing={1} pt={1} >
                <Grid item xs={7}>
                  <SelectWithLabel
                    options={typeOptions}
                    label={showLabel ? "Type" : ""}
                    size={size}
                    value={typeOptions.find(obj => {
                      return obj.value === row.typeCode
                    })}
                    onChange={e => handleRowChange({ target: { name: 'typeCode', value: e.value } }, index)} name="typeCode"
                  />
                </Grid>

                <Grid item xs={5}>
                  <InputWithLabel
                    label={!showLabel ? "" : showDescription ? "Qty" : "Quantity"}
                    name="quantity"
                    onChange={e => handleRowChange(e, index)}
                    error={row.quantityError}
                    value={row.quantity === 0 ? '' : row.quantity}
                    placeholder="0"
                    adornment="#"
                    mandatory={true && showDescription}
                    adornmentPosition="end"
                    size={size}
                    adornmentSize={showDescription ? '0.8rem' : '1.2rem'}
                  />
                </Grid>



              </Grid>
              {last && (!row.adr || !showDescription) &&
                <Grid container item xs={12} spacing={1} >
                  <Grid item xs={12} sx={{ marginTop: '-0.75rem', marginRight: '0rem' }}>
                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={
                      <Grid container justifyContent="space-between" >
                        <Grid item xs={12}>
                          Packages: {quantityCounter}
                        </Grid>
                      </Grid>
                    } />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          {/* Section 2: FTL/Konttityyppi - Mitat */}
          <Grid container item xs={12} md={!showDescription && goods.length === 1 && state.adr ? 5 : !showDescription && goods.length > 1 && state.adr ? 4.7 : !showDescription && goods.length > 1 ? 5.4 : !showDescription ? 6 : specialType ? 4 : 4} spacing={1} >
            <Grid container item xs={12} spacing={1}>
              {showFullTruck &&
                <Grid item xs={7} >
                  <SelectWithLabel
                    options={fullTrailerTypes}
                    label={
                      "Trailer type"
                    }
                    defaultValue={{ value: 1, label: 'Semi-trailer (13.6ldm)' }}
                    // error={row.fullTrailerTypeError}
                    size={size}
                    value={fullTrailerTypes.find(obj => {
                      return obj.value === row.fullTrailerType
                    })}
                    onChange={e => handleRowChange({ target: { name: 'fullTrailerType', value: e.value } }, index)} name="fullTrailerType"
                  />
                </Grid>
              }
              {showContainer &&
                <Grid item xs={5} >
                  <SelectWithLabel
                    options={containerTypes}
                    label={
                      "Container type"
                    }
                    error={row.containerTypeError}
                    size={size}
                    value={containerTypes.find(obj => {
                      return obj.value === row.containerType
                    })}
                    onChange={e => handleRowChange({ target: { name: 'containerType', value: e.value } }, index)} name="containerType"
                  />
                </Grid>
              }

              {!specialType &&
                <>
                  <Grid item xs={3} >
                    <InputWithLabel
                      label={showLabel ? "Length" : ""}
                      value={row.length === 0 ? '' : row.length}
                      readOnly={row.typeCode === 2 || row.typeCode === 3 || row.typeCode === 4 ? true : false}
                      disabled={row.typeCode === 2 || row.typeCode === 3 || row.typeCode === 4 ? true : false}
                      placeholder="0"
                      onChange={e => handleRowChange(e, index)}
                      error={row.lengthError} name="length"
                      mandatory={true && showDescription}
                      adornment="cm"
                      adornmentPosition="end"
                      size={size}
                      adornmentSize={showDescription ? '0.8rem' : '1.2rem'}
                    />
                  </Grid>

                  <Grid item xs={3} >
                    <InputWithLabel
                      label={showLabel ? "Width" : ""}
                      value={row.width === 0 ? '' : row.width}
                      readOnly={row.typeCode === 2 || row.typeCode === 3 || row.typeCode === 4 ? true : false}
                      disabled={row.typeCode === 2 || row.typeCode === 3 || row.typeCode === 4 ? true : false}
                      placeholder="0"
                      name="width"
                      onChange={e => handleRowChange(e, index)}
                      error={row.widthError}
                      mandatory={true && showDescription}
                      adornment="cm"
                      adornmentPosition="end"
                      size={size}
                      adornmentSize={showDescription ? '0.8rem' : '1.2rem'}
                    />
                  </Grid>
                  <Grid item xs={3} >
                    <InputWithLabel
                      label={showLabel ? "Height" : ""}
                      value={row.height === 0 ? '' : row.height}
                      placeholder="0"
                      name="height"
                      onChange={e => handleRowChange(e, index)}
                      error={row.heightError}
                      mandatory={true && showDescription}
                      adornment="cm"
                      adornmentPosition="end"
                      size={size}
                      adornmentSize={showDescription ? '0.8rem' : '1.2rem'}
                    />
                  </Grid>
                </>
              }
              <Grid item xs={specialType ? 5 : 3} >
                <InputWithLabel
                  label={specialType ? "Total weight" : showLabel ? "Weight" : ""}
                  value={row.weight === 0 ? '' : row.weight}
                  placeholder="0"
                  name="weight"
                  mandatory={true && showDescription}
                  onChange={e => handleRowChange(e, index)}
                  error={row.weightError}
                  adornment="kg"
                  adornmentPosition="end"
                  size={size}
                  adornmentSize={showDescription ? '0.8rem' : '1.2rem'}
                />
              </Grid>
            </Grid>
            {last && (!row.adr || !showDescription) &&
              <Grid container item xs={12} spacing={1} >
                <Grid item xs={12} sx={{ marginTop: '-0.75rem', marginRight: '0rem' }}>
                  <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Per Item" />
                </Grid>
              </Grid>
            }
          </Grid>


          {/* Section 3: stack & adr */}
          <Grid container item xs={12} md={specialType ?  (!showDescription && goods.length === 1 && shipmentFormType === 'insta' ? 3 : 5) : !showDescription && goods.length === 1 && state.adr ? 4 : !showDescription && goods.length > 1 && state.adr ? 4.3 : !showDescription && goods.length > 1 ? 3.5 : showDescription ? 6 : 3} spacing={1} sx={{ width: '100%'}}>

            <Grid container item xs={12} md={!showDescription && goods.length > 1 ? 10.5 : !showDescription ? 12 : showDescription && !state.availableAdditionalServices.adr && instant ? 3 : 6} spacing={1} sx={{ width: '100%' }}>
              {!specialType &&
                <Grid item xs={showDescription && !state.availableAdditionalServices.adr && instant ? 12 : goods.length > 1 ? state.adr && !showDescription ? 3.5 : 6 : state.adr && !showDescription ? 3.5 : 6} >
                  <SelectWithLabel
                    options={stackableOptions}
                    label={showLabel ? "Stackable" : ""}
                    isDisabled={row.typeCode === 7 ? true : false}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'red',
                      }),
                    }}
                    size={size}
                    value={stackableOptions.find(obj => {
                      return obj.value === row.stackable
                    })}
                    onChange={e => handleRowChange({ target: { name: 'stackable', value: e.value } }, index)}
                    name="stackable"
                  />
                </Grid>
              }
              { (!showDescription || !instant || (showDescription && state.availableAdditionalServices.adr) )&& 
              <Grid item xs={specialType ? 12 : goods.length > 1 ? state.adr && !showDescription ? 3.5 : 6 : state.adr && !showDescription ? 3.5 : 6}>
                {state.adr || showDescription ? (

                  <SelectWithLabel
                    options={adrOptions}
                    label={
                      (showLabel) && (
                        <>
                          Adr
                          <Tooltip title="Dangerous goods" placement="top">
                            <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                          </Tooltip>
                        </>
                      )
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'red',
                      }),
                    }}
                    size={size}
                    value={adrOptions.find(obj => {
                      return obj.value === row.adr
                    })}
                    onChange={e => handleRowChange({ target: { name: 'adr', value: e.value } }, index)}
                    name="adr" />
                ) : (
                  <SelectWithLabel
                    options={adrOptions}
                    label={
                      (showLabel) && (
                        <>
                          Dangerous goods
                          {/* <Tooltip title="Dangerous goods" placement="top">
                        <InfoOutlinedIcon color="primary" sx={{ marginLeft: '10px' }} />
                      </Tooltip> */}
                        </>
                      )
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: 'red',
                      }),
                    }}
                    size={size}
                    value={adrOptions.find(obj => {
                      return obj.value === row.adr
                    })}
                    onChange={e => handleRowChange({ target: { name: 'adr', value: e.value } }, index)}
                    name="adr" />
                )}
              </Grid>
              }
              {row.adr && !showDescription &&
                <Grid item xs={5}>

                  <SelectWithLabel

                    // value={adrTypes.find(obj => {
                    //   return obj.value === state.adrClass
                    // })}
                    label={showLabel ? "Class" : ""}
                    value={row.adrClass}
                    options={adrTypes}
                    fullWidth
                    size="medium"
                    name="adrClass"
                    error={row.adrClassError}
                    isMulti={true}
                    delimiter=","
                    onChange={e => handleAdrClassChange('adrClass', e, index)}
                  // onChange={e => handleAdrClassChange({ target: { name: 'adrClass', value: e.value } }, index)}
                  />

                  {/* <SelectWithLabel
                    label={showLabel ? "Class" : ""}
                    name="adrClass"
                    error={state.adrClassError}
                    options={adrTypes}
                    onChange={e => handleAdrClassChange({ target: { name: 'adrClass', value: e.value } }, index)}
                  /> */}
                </Grid>
              }
            </Grid>

            {/* Section 4: loput */}

            <Grid container item xs={!showDescription && goods.length > 1 ? 1.5 : showDescription && !state.availableAdditionalServices.adr && instant ? 8.99 : 5.99} spacing={1} sx={{ width: '100%'}}>
              {showDescription &&

                <Grid item xs={goods.length > 1 ? 10 : 12} >
                  <InputWithLabel
                    id="outlined-multiline-static"
                    label={index === 0 ? "Goods description" : goods[index - 1].adr ? "Goods description" : ""}

                    adornmentPosition="end"
                    mandatory={true}
                    size={size}

                    value={row.description}
                    error={row.descriptionError}
                    placeholder="description of goods"
                    name="description"
                    onChange={e => handleRowChangeWithMaxLenght(e, index, 'Goods description', 26,row.description)}
                  />
                </Grid>
              }


              {goods.length > 1 &&
                <Grid container item xs={0.5} sx={{ paddingLeft: '0px !important' }}>
                  <Grid item container xs={12} >
                    <Grid item xs={12}>
                      {showLabel ? (
                        <SuiBox display="inline-block">
                          <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight='light'
                            textTransform="capitalize"
                          >
                            Delete
                          </SuiTypography>
                        </SuiBox>) : null}
                    </Grid>
                    <Grid item xs={12} >
                      <SuiButton color="error" sx={{ minWidth: 'unset !important' }} variant="text" onClick={e => removeGoodsLine(index)}>
                        <ClearOutlinedIcon sx={{ height: '100%' }} />
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              }

            </Grid>
            {/* </Grid> */}
            {last && (!row.adr || !showDescription) &&
              <Grid item container xs={12} spacing={0.99} md={!showDescription && goods.length > 1 ? 10.5 : showDescription && goods.length > 1 ? 11 : 12}>
                <Grid sx={{ marginTop: '-0.75rem', marginRight: '0rem' }} item xs={12}>

                  <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={
                    calculating ?
                      <Progress size="body" />

                      :
                      <Grid container justifyContent="space-between" >
                        <Grid item xs={3}>
                          Total:
                        </Grid>
                        <Grid item xs={3}>
                          {state.totalVolume?.toFixed(2) + "m3 "}
                        </Grid>
                        <Grid item xs={3}>
                          {state.totalLdm?.toFixed(1) + "ldm "}
                        </Grid>
                        <Grid item xs={3}>
                          {state.billingWeight?.toFixed(0) + "kg"}
                        </Grid>
                      </Grid>


                  } />


                </Grid>
              </Grid>
            }
          </Grid>
          {row.adr && showDescription && 
            <Grid item container  xs={goods.length > 1 ? 11.5 : 12}  spacing={2} mt={-1} >
              <Grid item xs={12} sx={{ backgroundColor: 'rgba(238, 238, 238, 0.5)', borderRadius: '10px', padding: '10px', paddingTop: '5px !important'}} mb={3} ml={0} spacing={2} justifyContent="flex-start" alignItems="flex-start">
                  {goods[index].adrRow.map((x, i) =>
                    <AdrRow key={`adrRow-${i}`} goodsIndex={index} adrIndex={i} goods={goods} shipmentFormType={shipmentFormType}/>
                  )}
              </Grid>
            </Grid>
          }

        </Grid>



        {last && row.adr && showDescription &&
          <Grid item container xs={12} sx={{ paddingTop: '10px !important' }} spacing={2}>
            <Grid item container xs={12} md={specialType ? 5 : showDescription && row.adr ? 12 : showDescription && goods.length === 1 ? 6 : showDescription && goods.length > 1 ? 5.5 : !showDescription && goods.length === 1 ? 3 : !showDescription && goods.length > 1 ? 2.8 : goods.length > 1 ? 3.2 : 3} sx={{ paddingLeft: '0px !important' }} >
              <Grid sx={{ marginTop: '-0.75rem', marginRight: '0rem' }} item xs={12}>

                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={
                  calculating ?
                    <Progress size="body" />

                    :
                    <Grid container justifyContent="space-between" >
                      <Grid item xs={3}>
                        Total:
                      </Grid>
                      <Grid item xs={3}>
                        {state.totalVolume?.toFixed(2) + "m3 "}
                      </Grid>
                      <Grid item xs={3}>
                        {state.totalLdm?.toFixed(1) + "ldm "}
                      </Grid>
                      <Grid item xs={3}>
                        {state.billingWeight?.toFixed(0) + "kg"}
                      </Grid>
                    </Grid>


                } />


              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>





    </>
  )
}