import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactCountryFlag from "react-country-flag"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export default function CustomerService() {
   return (
       <Box sx={{ width: '215px', height: '200px', p: 0.5,textAlign:'left' }}>

           <Typography sx={{ fontSize: 13, px: 2}} color="text.primary" gutterBottom>
               Customer service:
           </Typography>
           <Typography sx={{ fontSize: 13, px: 2}} color="text.primary" gutterBottom>
               <EmailIcon/> bookings@freightopt.com
           </Typography>
           <Typography sx={{ fontSize: 13, px: 2}} color="text.primary" gutterBottom>
           <LocalPhoneIcon/>  +358 40 1961 317
           </Typography>

        
           <Typography sx={{ fontSize: 12, px: 2, pt:1}} color="text.secondary" gutterBottom>
               Business days at:
           </Typography>
           <Typography sx={{ fontSize: 12, px: 2, py: 0 }} color="text.secondary" gutterBottom>
               Finland: 08:00-16:00
           </Typography>
           <Typography sx={{ fontSize: 12, px: 2, py: 0 }} color="text.secondary" gutterBottom>
               Sweden: 08:00-16:00
           </Typography>
           <Typography sx={{ fontSize: 12, px: 2, py: 0 }} color="text.secondary" gutterBottom>
               Languages:
               <ReactCountryFlag svg   countryCode={"FI"} width="20" style={{ marginRight: '0.5rem',marginLeft:'0.5rem' }} />
                <ReactCountryFlag svg   countryCode={"SE"} width="20" style={{ marginRight: '0.5rem' }} />
                <ReactCountryFlag svg   countryCode={"GB"} width="20" style={{ marginRight: '0.5rem' }} />

           </Typography>
       </Box>
   )
}


