import { Id } from '_components/BookingsTable/CellFunctions';
import { authHeader } from '../_helpers';
import { handleResponse } from './util'

export const adminService = {
    createRole,
    getAllRoles,
    getAllCustomers,
    createOrUpdateCustomer,
    deleteCustomer,
    getAllUsers,
    getUser,
    createOrUpdateUser,
    newPasswordForUser,
    deleteUser,
    getAllCarriers,
    createOrUpdateCarrier,
    deleteCarrier,
    getAllCalculators,
    getCalculator,
    deleteCalculator,
    createOrUpdateCalculator,
    refreshCalculatorsInCache,
    CreateOrUpdateChangingCost,
    getChangingCostsForEdit,
    deleteChangingCost,
    saveTrackingNumber,
    forwardDocuments,
    sendToApi,
    getLabels,
    generateInvoicePatches,
    getInvoicePatches,
    getAllCustomersToImport,
    importCustomers,
    setCompleted,
    setCanceled,
    refreshCache,
    getEventLogs,
    forwardSpotRequest,
    savePickupReference
};
const requestOptions = {
    method: 'GET',
    headers: authHeader()
};
const getOptions = (method) => {
    return {
        method: method,
        headers: authHeader()
    };
}

function createRole(role) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateRole/' + role, requestOptions).then(handleResponse)

}

function getAllCustomers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllCustomers', requestOptions).then(handleResponse)

}

function createOrUpdateCustomer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateOrUpdateCustomer', requestOptions).then(handleResponse)

}



function deleteCustomer(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/DeleteCustomer/' + id, requestOptions).then(handleResponse)

}


function getAllUsers() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllUsers', getOptions('GET')).then(handleResponse) }
function getAllRoles() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllRoles', getOptions('GET')).then(handleResponse) }
function getUser(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetUser/' + id, getOptions('GET')).then(handleResponse) }
function deleteUser(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/DeleteUser/' + id, getOptions('DELETE')).then(handleResponse) }
function createOrUpdateUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateOrUpdateUser', requestOptions).then(handleResponse)
}
function newPasswordForUser(id) {
    return fetch(process.env.REACT_APP_API_URL + 'Admin/NewPasswordForUser/' + id, getOptions('GET')).then(handleResponse)
}

function setCompleted(id) {
    return fetch(process.env.REACT_APP_API_URL + 'Admin/SetShipmentCompleted/' + id, getOptions('PUT')).then(handleResponse)
}

function setCanceled(id) {
    return fetch(process.env.REACT_APP_API_URL + 'Admin/SetShipmentCancelled/' + id, getOptions('PUT')).then(handleResponse)
}

function getAllCarriers() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllCarriers', getOptions('GET')).then(handleResponse) }
function createOrUpdateCarrier(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateOrUpdateCarrier', requestOptions).then(handleResponse)

}
function deleteCarrier(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/DeleteCarrier/' + id, getOptions('DELETE')).then(handleResponse) }


function getAllCalculators() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllCalculators', getOptions('GET')).then(handleResponse) }
function getCalculator(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetCalculator/' + id, getOptions('GET')).then(handleResponse) }
function deleteCalculator(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/DeleteCalculator/' + id, getOptions('DELETE')).then(handleResponse) }
function createOrUpdateCalculator(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateOrUpdateCalculator', requestOptions).then(handleResponse)

}

function refreshCalculatorsInCache() { return fetch(process.env.REACT_APP_API_URL + 'Admin/RefreshCalculatorsInCache', getOptions('GET')).then(handleResponse) }

function refreshCache() { return fetch(process.env.REACT_APP_API_URL + 'Admin/RefreshCache', getOptions('GET')).then(handleResponse) }


function CreateOrUpdateChangingCost(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/CreateOrUpdateChangingCost', requestOptions).then(handleResponse)

}

function forwardDocuments(model) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/ForwardDocuments', requestOptions).then(handleResponse)

}

function forwardSpotRequest(model) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/ForwardSpotRequest', requestOptions).then(handleResponse)

}

function getChangingCostsForEdit() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetChangingCostsForEditing', getOptions('GET')).then(handleResponse) }



function deleteChangingCost(id) { return fetch(process.env.REACT_APP_API_URL + 'Admin/DeleteChangingCost/' + id, getOptions('DELETE')).then(handleResponse) }

function saveTrackingNumber(bookingId, trackingNumber) { return fetch(process.env.REACT_APP_API_URL + 'Admin/UpdateTrackingNumberForBooking/' + bookingId + "?trackingNumber=" + trackingNumber, getOptions('POST')).then(handleResponse) }
function savePickupReference(bookingId, reference) { return fetch(process.env.REACT_APP_API_URL + 'Admin/UpdatePickupRefForBooking/' + bookingId + "?pickupref=" + reference, getOptions('POST')).then(handleResponse) }
function sendToApi(bookingId) { return fetch(process.env.REACT_APP_API_URL + 'Admin/SendToApi/' + bookingId, getOptions('POST')).then(handleResponse) }
function getLabels(bookingId) { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetLabels/' + bookingId, getOptions('POST')).then(handleResponse) }

function generateInvoicePatches(date) {

    const model = {
        Date: date
    }

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };

    return fetch(process.env.REACT_APP_API_URL + 'Admin/GenerateInvoicePatch', requestOptions).then(handleResponse)

}

function getInvoicePatches() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetInvoicePatches', getOptions('GET')).then(handleResponse) }

function getAllCustomersToImport() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetAllCustomersToImport', getOptions('GET')).then(handleResponse) }

function importCustomers() { return fetch(process.env.REACT_APP_API_URL + 'Admin/importCustomers', getOptions('GET')).then(handleResponse) }
function getEventLogs() { return fetch(process.env.REACT_APP_API_URL + 'Admin/GetLogs', getOptions('GET')).then(handleResponse) }