import React from 'react';
import Grid from '@mui/material/Grid';
import { ShipmentInformationFormActions } from '../../_actions';
import { useDispatch} from 'react-redux';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";

import { adrTypes } from '_helpers'
import Chip from '@mui/material/Chip';
import SuiButton from "_softUI/components/SuiButton";



import Typography from '@mui/material/Typography';

import AdditionalService from './AdditionalService';



export function AdditionalServiceContainer({ instant, next, visible, validateLength, stateParent }) {

    const state = stateParent.data

    const dispatch = useDispatch()
    function handleChange(event) {

        dispatch(ShipmentInformationFormActions.updateBasicValue(event.target.name, event.target.value));
    }

    function handleChangeWithMaxLenght(event, label, maxLenght,oldValue) {
        const newValue = validateLength(label, event.target.value, maxLenght,oldValue)
        console.log(newValue)
        dispatch(ShipmentInformationFormActions.updateBasicValue(event.target.name, newValue));
    }

    function handleSelectChange(name, value) {
        if (name === 'transportationType') {
            dispatch(ShipmentInformationFormActions.resetGoods());
        }
        dispatch(ShipmentInformationFormActions.updateBasicValue(name, value));
    }

    function handleAdditionalServiceChange(name, value) {
        console.log(value)
        dispatch(ShipmentInformationFormActions.updateBasicValue(name, value));
    }

    function setCollapseUnvisible() {

        next('details');



    }



    const insurancePriceObject = state.pricingDetails.find(x => { return x.name === 'insurance' })
    const insurancePrice = insurancePriceObject ? insurancePriceObject.doubleValue : 0;

    const adrPriceObject = state.pricingDetails.find(x => { return x.name === 'adr' })
    const adrPrice = adrPriceObject ? adrPriceObject.doubleValue : 0;

    const tailLiftObject = state.pricingDetails.find(x => { return x.name === 'tailLift' })
    const tailLiftPrice = tailLiftObject ? tailLiftObject.doubleValue : 0;


    const callOnPickupPriceObject = state.pricingDetails.find(x => { return x.name === 'callOnPickup' })
    const callOnPickupPrice = callOnPickupPriceObject ? callOnPickupPriceObject.doubleValue : 0;

    const callOnDeliveryPriceObject = state.pricingDetails.find(x => { return x.name === 'callOnDelivery' })
    const callOnDeliveryPrice = callOnDeliveryPriceObject ? callOnDeliveryPriceObject.doubleValue : 0;

    const consumerDeliveryPriceObject = state.pricingDetails.find(x => { return x.name === 'consumerDelivery' })
    const consumerDeliveryPrice = consumerDeliveryPriceObject ? consumerDeliveryPriceObject.doubleValue : 0;

    const heatedTransportPriceObject = state.pricingDetails.find(x => { return x.name === 'heatedTransport' })
    const heatedTransportPrice = heatedTransportPriceObject ? heatedTransportPriceObject.doubleValue : 0;



    return (




        <Grid sx={{}} item xs={12} container spacing={2} justifyContent="space-evenly" alignItems="stretch" >






            <Grid item container xs={12}>





                {/* </Grid> */}
                <Grid item xs={12}>
                    <Grid container spacing={1}>



                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <Grid container item xs={12} sx={{ borderBottom: '1px solid #eeeeee' }}>
                                        <Grid item xs={1} container alignItems="flex-start">
                                            <Typography variant='subtitle2' sx={{ pl: 0.2, color: 'text.secondary' }} >
                                                Select
                                            </Typography>
                                        </Grid>
                                        <Grid item container xs={11}>

                                            <Grid item container xs={9}>
                                                <Grid item xs={8} container alignItems="center">
                                                    <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary' }} >
                                                        Service
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={4} alignItems="center">
                                                    <Typography variant='subtitle2' sx={{ pl: 1, color: 'text.secondary' }} >
                                                        Input
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {instant &&
                                            <Grid item xs={3} container alignItems="center" justifyContent="center">
                                                <Typography variant='subtitle2' sx={{ color: 'text.secondary' }} >
                                                    Price
                                                </Typography>
                                            </Grid>
                                            }
                                        </Grid>


                                    </Grid>

                                    <Grid container item xs={12}>


                                        <Grid container item xs={12} spacing={1}>
                                            {(state.groupage || state.availableAdditionalServices.tailLift || !instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService
                                                        title={state.groupage || state.direction === 1 ? "Tail lift loading / dismantling" : state.direction === 2 ? "Tail lift loading" : "Tail lift dismantling"}
                                                        instructions="Automatically included in groupage cargo"
                                                        state={state.tailLift}
                                                        name="tailLift"
                                                        showPrice={instant}
                                                        force={state.groupage}
                                                        currency={state.currency}
                                                        value={state.groupage ? 'Included' : tailLiftPrice}
                                                        changeHandler={handleAdditionalServiceChange} />
                                                </Grid>
                                            }
                                            {(state.availableAdditionalServices.callOnPickup || !instant) && 
                                                <Grid item xs={12}>

                                                    <AdditionalService
                                                        title="Call before pickup"
                                                        instructions="Contact person will be contacted before pickup"
                                                        state={state.callOnPickup}
                                                        name="callOnPickup"
                                                        showPrice={instant}
                                                        currency={state.currency}
                                                        value={callOnPickupPrice}
                                                        changeHandler={handleAdditionalServiceChange}
                                                    />
                                                </Grid>}
                                            {(state.availableAdditionalServices.callOnDelivery || !instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService
                                                        title="Call before delivery"
                                                        instructions="Contact person will be contacted before delivery"
                                                        state={state.callOnDelivery}
                                                        name="callOnDelivery"
                                                        currency={state.currency}
                                                        showPrice={instant}
                                                        value={callOnDeliveryPrice}
                                                        changeHandler={handleAdditionalServiceChange}
                                                    />
                                                </Grid>}
                                            {(state.availableAdditionalServices.insurance || !instant) &&
                                                <Grid item xs={12}>
                                                    <AdditionalService title="Insurance (Packed, general goods)" showPrice={instant} currency={state.currency} value={insurancePrice} state={state.insurance} calculating={stateParent.calculatingInsurance} instructions="Input the value of goods to calculate the price" name="insurance" changeHandler={handleAdditionalServiceChange}>
                                                        <InputWithLabel

                                                            name="goodsValue"
                                                            onChange={e => handleChange(e)}
                                                            error={state.goodsValueError}
                                                            value={state.goodsValue}
                                                            placeholder="Value of the goods"
                                                            disabled={!state.insurance}
                                                            adornment={state.currency}
                                                            label=""
                                                            adornmentPosition="end"

                                                        />

                                                    </AdditionalService>
                                                    {state.goodsValueOver20K && 
            <Grid item xs={12}>
            <Chip label="For good values over 20 000€, please contact customer service." sx={{width:'100%', borderRadius:'5px'}} size="small" color="error" variant="outlined" />
            </Grid>
            }
                                                </Grid>
                                            }
                                            {/* {(state.availableAdditionalServices.adr || !instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService title="ADR" showPrice={instant} currency={state.currency} value={adrPrice} name="adr" state={state.adr} calculating={stateParent.calculatingAdr} instructions="Select the IMO class to calculate the price" changeHandler={handleAdditionalServiceChange} >

                                                        <SelectWithLabel
                                                            // value={adrTypes.find(obj => {
                                                            //   return obj.value === state.adrClass
                                                            // })}
                                                            value={state.adrClass}
                                                            options={adrTypes}
                                                            error={state.adrClassError}
                                                            fullWidth
                                                            size="medium"
                                                            name="adrClass"
                                                            isMulti={true}
                                                            delimiter=","
                                                            onChange={e => handleSelectChange('adrClass', e)}
                                                            label=""
                                                        />

                                                    </AdditionalService>
                                                </Grid>
                                            } */}
                                           {(state.availableAdditionalServices.consumerDelivery || !instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService
                                                        title="Consumer delivery"
                                                        instructions="Receiver of the goods is consumer"
                                                        state={state.consumerDelivery}
                                                        name="consumerDelivery"
                                                        currency={state.currency}
                                                        showPrice={instant}
                                                        value={consumerDeliveryPrice}
                                                        changeHandler={handleAdditionalServiceChange}
                                                    />
                                                </Grid>}
                                                {(state.availableAdditionalServices.heatedTransport || !instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService
                                                        title="Heated transport"
                                                        instructions="Available between 1.10 and 30.4"
                                                        state={state.heatedTransport}
                                                        name="heatedTransport"
                                                        currency={state.currency}
                                                        showPrice={instant}
                                                        value={heatedTransportPrice}
                                                        changeHandler={handleAdditionalServiceChange}
                                                    />
                                                </Grid>}
                                             {(!instant) &&
                                                <Grid item xs={12}>

                                                    <AdditionalService title="Temperature control" showPrice={false} currency={"€"} value={0} name="temperatureControl" state={state.temperatureControl} calculating={stateParent.calculatingTemperature} instructions="Please fill desired temperature" changeHandler={handleAdditionalServiceChange} >

                                                        <InputWithLabel

                                                            name="temperature"
                                                            onChange={e => handleChange(e)}
                                                            error={state.temperatureError}
                                                            value={state.temperature}
                                                            placeholder="Temperature"
                                                            adornment={"C°"}
                                                            label=""
                                                            adornmentPosition="end"

                                                        />

                                                    </AdditionalService>
                                                </Grid>
                                            }
                                     


                                        </Grid>
                                    </Grid>

                                </Grid>




                            </Grid>
                        </Grid>
                        {next &&
                            <Grid item container justifyContent="flex-end">
                                <SuiButton color="secondary" variant="contained" onClick={setCollapseUnvisible}>
                                    Apply
                                </SuiButton>
                            </Grid>
                        }

                    </Grid>
                </Grid>

            </Grid>

        </Grid>


    )
}